$(document).ready(function () {
    $('.panel-list li').click(function () {
        // Clear tablink categories 'active' state
        $('.tablinks').removeClass('active');

        $.ajax({ url: this.id,
            success: function (result) {
                $('#suportdocs').html(result);
                $('#mobSystemqsg').click();
                $('#desSystemqsg').click();
            } });
    });

    jQuery(function () {
        jQuery($('.firstlelement')).click();
    });
});
